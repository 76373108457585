export default [{
	title: 'Solicitudes',
	icon: 'FileTextIcon',
	children: [
		{
			title: 'Lista',
			route: 'requests-list',
			action: 'read',
			resource: 'requests'
		},
		{
			title: 'Nuevo',
			route: 'requests-create',
			action: 'create',
			resource: 'requests'
		},
	]
}];